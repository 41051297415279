
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import {ICommentItem} from "@/models/logistics_model";
import {useRoute, useRouter} from "vue-router";
import {dateFormat} from "@/utils/common";
import {message} from "ant-design-vue/es";

interface IState {
  detail?: ICommentItem
  loading: boolean,
}

export default defineComponent({
  name: "ComplaintDetail",
  setup() {
    let editId: any;
    const route = useRoute()
    const router = useRouter()

    const state: IState = reactive({
      detail: undefined,
      loading: false,
    })

    // 获取文章详情
    const getDetail = async () => {
      const {id} = route.query
      if (id) {
        editId = id
        const res = await ApiLogistics.getCommentDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          state.detail = data
        }
      }
    }

    // 删除
    const onDel = async () => {
      state.loading = true
      const res = await ApiLogistics.delComment(state.detail?.id)
      message.destroy()
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('删除成功')
        setTimeout(() => router.back(), 300)
      } else {
        message.error(res?.msg || '删除失败');
      }
    }

    // 显示隐藏
    const setStatus = async () => {
      state.loading = true
      const res = await ApiLogistics.setCommentStatus(state.detail?.id, state.detail?.status === 0 ? 1 : 0)
      message.destroy()
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('设置成功')
        await getDetail()
      } else {
        message.error(res?.msg || '设置失败');
      }
    }

    onMounted(() => {
      getDetail()
    })

    return {
      ...toRefs(state),
      dateFormat,
      onDel,
      setStatus,
    }
  }
})
